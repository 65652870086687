//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkParams from '@/scripts/auth/checkParams.js'
export default {
    name: 'Group',
    computed: {
      canUpload() {
          if (this.isOwner || this.isAdmin) {
                return true;
          }

          return false;
      }  
    },
    components: {
        CoverPhoto: () => import('@/components/CoverPhoto.vue'),
        AchievementsList: () => import('@/components/Achievements/AchievementsList.vue'),
        GroupNamestrip: () => import('@/components/Group/GroupNamestrip.vue'),
        GroupDashboard: () => import('@/components/Group/GroupDashboard.vue'),
        GroupBulletin: () => import('@/components/Group/GroupBulletin.vue'),
        GroupPublicJoin: () => import('@/components/Group/GroupPublicJoin.vue'),
        GroupMembers: () => import('@/components/Group/GroupMembers.vue'),
        PostContainer: () => import('@/components/Posts/PostContainer.vue'),
        Gallery: () => import('@/components/Gallery/Gallery.vue')
    },
    data() {
        return {
            group: null,
            isMember: false,
            isOwner: false,
            isAdmin: false,
            membersCount: 0,
            isFullContent: false,
            state: 'posts',
            isPreview: true,
            metaTitle:''
        }
    },
    metaInfo(){
        return{
            title:this.metaTitle
        }
    },
    methods: {
        fullContent(){
            return {
                'isFullContent': this.isFullContent,
            }
        },        
        ishidden(){
            return{
                'hidden': this.isFullContent
            }
        },
        changeState(state) {
            this.state = state;
            if(state != 'posts'){
                this.isFullContent = true
            }else{
                this.isFullContent = false
            }
        },
        validateID(id){
           checkParams.validateEntityParams(id)
        }
    },
    watch: {
        '$route.params.id' () {
            window.location.reload();
        },
    },
    created() {
        this.validateID(this.$route.params.id)
        this.$q.loading.show();
        this.$http({
            method: 'GET',
                url: 'groups/' + this.$route.params.id
            }).then((response) => {
                this.$q.loading.hide();
                switch (response.data['status']) {
                    case 'has_invite':
                        this.$router.push({
                            name: 'GroupInvite', 
                            params: {
                                invite: response.data['body']['invite'],
                                id: this.$route.params.id
                            }
                        });
                    break;
                    case 'group_retrieved':
                        this.group = response.data['body']['group'];
                        this.metaTitle = this.group.name
                        this.isOwner = response.data['body']['isOwner'];
                        this.isMember = response.data['body']['isMember'];
                        this.isAdmin = response.data['body']['isAdmin'];
                        this.membersCount = response.data['body']['membersCount'];
                    break;
                }
            }).catch((error) => {
                this.$q.loading.hide();
                switch (error.response.status) {
                    case 404: {
                        this.$router.push({name: 'NotFound404'});
                    }
                }
            });
        }
}
