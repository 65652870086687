// Validate Required parameters
let checkParams = {
    validateProfileParams ( id , user_id ) {
        if ( ( id == null || id == undefined) && user_id == null ){
            this.$router.push('/notfound')
        }
    },

    validateEntityParams( id ){
        if ( id == null || id == undefined) {
            this.$router.push('/notfound')
        }
    }
}

export default checkParams